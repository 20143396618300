
$('.jobs--toggles .toggle').on('click', function () {
    $(this).toggleClass('active');
    $(this).find('dd').slideToggle();
});

const JobCounter = $('#job--count');

(function () {
    let jobCounter = document.querySelectorAll('.jobs--toggles .toggle dd');
    let jobCounterArray = [...jobCounter]
    jobCounterArray.map(dd => {
        let angebot = '';
        dd.childElementCount > 1 ? angebot = ' Angebote' : angebot = ' Angebot'
        console.log(dd.parentElement.firstElementChild.querySelector('#job--count').innerHTML = ` – ${dd.childElementCount}${angebot}`)
    })
})()
